let dev = false;
window.addEventListener("load", function () {
  let lis = Array.from(document.querySelectorAll("li.slide-num"));
  lis.forEach((li) => {
    li.onclick = (ev) => {
      let slider = ev.target.closest(".slider");
      slider.querySelector('.slide-num.active').classList.remove('active');
      li.classList.add('active');
      let id = parseInt(li.dataset.slideId);
      removeAnimated(slider);
      showSlide(slider, id);
    };
  });

  let buttons = Array.from(document.querySelectorAll(".prev, .next"));
  buttons.forEach((button) => {
    button.onclick = (ev) => {
      let slider = button.closest(".slider");
      let li = slider.querySelector(".slide-num.active");
      let prevNext = Array.from(button.classList).includes('prev');
      let sibbling = prevNext?li.previousElementSibling:li.nextElementSibling;
      if (!sibbling) return;

      li.classList.remove("active");
      sibbling.classList.add("active");
      let id = sibbling.dataset.slideId;
      removeAnimated(slider);
      showSlide(slider, id);
    };
  });

  //let playButton = document.querySelector(".vjs-big-play-button");
  //playButton.classList.add("animate__animated", "animate__pulse", "animate__infinite");
  let video = document.querySelector('#video-estimcad');
  let videoPlayButton = document.querySelector('#video-play-btn');
  let playButtons = document.querySelectorAll('a[href="#video-estimcad"]')
  playButtons.forEach(button => button.onclick = () => video.play());

  video.onplay = (ev) => videoPlayButton.classList.add('hidden');
  video.onpause = (ev) => videoPlayButton.classList.remove('hidden');

  // DEV
  let dev_tips = Array.from(document.getElementsByClassName("tip"));
  dev_tips.forEach((tip) => {
    dev_dragElement(tip)
    tip.onwheel = e => {
      //up
      if (e.deltaY < 0) {
        dev_resizeTip(tip, 1);
      }
      //down
      if (e.deltaY > 0) {
        dev_resizeTip(tip, -1);
      }
    }
  });
});

function dev_dragElement(elmnt) {
  //Copy-paste from https://www.w3schools.com/howto/howto_js_draggable.asp
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    if (dev === false) return;
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    //if (dev === false) return;
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
    dev_freezeTips();
  }
}

function dev_freezeTips() {
  //console.log('freeze');
  let tips = Array.from(document.getElementsByClassName("tip"));

  //let t = tips.map(el => ({top: el.style.top, left: el.style.left}));

  tips.forEach((tip) => {
    if (tip.style.top.includes("px")) {
      let pp = parseInt(tip.style.top);
      tip.style.top = (pp / tip.parentElement.scrollHeight) * 100 + "%";
    }
    if (tip.style.left.includes("px")) {
      let pp = parseInt(tip.style.left);
      tip.style.left = (pp / tip.parentElement.scrollWidth) * 100 + "%";
    }
  });
}

function dev_resizeTip(tip, amt) {
  if (dev === false) return;
  tip.style.maxWidth = parseInt(tip.style.maxWidth) + amt + "%";
}

function removeAnimated(el) {
  Array.from(el.querySelectorAll(".animate__animated")).forEach((animated) =>
    animated.classList.remove("animate__animated")
  );
}

function hideSlides(slider) {
  Array.from(slider.querySelectorAll(".slide")).forEach((s) =>
    s.classList.add("hidden")
  );
}

function showSlide(slider, id) {
  //let slide = slider.querySelector(".slide[data-slide-id=\"" + id + "\"]");
  let slides = Array.from(slider.querySelectorAll(".slide"));
  let slide = slides.find((s) => s.dataset.slideId == id);
  hideSlides(slider);
  slide.classList.remove("hidden");
}